<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
            <v-row>
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                    class="mt-3"
                    :label="$store.getters.translate('files')"
                    placeholder="Drop file to upload"
                    show-size
                    ref="file_input"
                    v-model="record.file"/>
              </v-col>
            </v-row>
            <v-list dense>
              <v-toolbar flat dense>
                {{ $store.getters.translate("linked_assets") }}
                <v-spacer/>
                <v-btn v-if="!parent_id" @click.stop="linkDocument()" block text color="primary" prepend-icon small>
                  {{ $store.getters.translate('link_document_to_asset') }}
                  <v-icon small class="ml-2">mdi-link</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider/>
              <template v-for="(asset, index) in record.assets">
                <v-list-item :key="index" color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <a @click="$emit('edit', asset.id)">
                        {{ asset.name }}
                        <small v-if="asset.parent_id">({{ asset.platformName }})</small>
                      </a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <base-tags v-if="!parent_id && tags_required" v-model="record.tags" :modal_name="page.name"/>
          </v-card-text>
        </div>
        <base-link-asset modal_name="link_asset" v-if="!parent_id" ref="link_asset" class="mb-3" @refresh="addAssetIds" :current_assets="record.asset_ids" :module_name="page.name"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseLinkAsset from "../../components/commonComponents/BaseLinkAsset";
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseLinkAsset,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("document"),
        name: "documents",
        model: "document"
      },
      loading: false,
      record: {
        assets: [],
        asset_ids: []
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      parent_id: null,
      file: "",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(document = null, selected_asset = null, tags = null) {
      if(document) {
        this.record = {
          name: document.name,
          description: document.description,
          asset_ids: this.$lodash.map(document.assets, 'id'),
          assets: document.assets,
          tags: document.tags,
          type: document.type,
          subtype: document.subtype,
          required_time: document.required_time,
          equipment_type: document.equipment_type,
        };
        if(document.latest_revision_id) {
          this.parent_id = document.latest_revision_id;
        }
        else {
          this.parent_id = document.id;
        }
      }
      else if(selected_asset) {
        this.record = {
          asset_ids: [selected_asset.id],
          assets: [selected_asset],
          tags: selected_asset.tags,
        };
      }
      else if(tags) {
        this.record = {
          tags: tags,
          assets: [],
          asset_ids: []
        };
      }
      else {
        this.record = {
          assets: [],
          asset_ids: []
        };
      }
      this.openModal(this.modal_name);
      this.getFields();
    },
    addAssetIds(ids) {
      this.record.asset_ids = ids;
      this.record.assets = [];
      this.record.asset_ids.forEach((item) => {
        this.$http
            .get(this.$store.getters.appUrl + "v2/assets/" + item)
            .then((response) => {
              this.record.assets.push(response.data);
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      });
    },
    save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if (this.record.commissioned == true) {
            this.record.commissioned = 1;
          }
          if (this.record.punch_item == true) {
            this.record.punch_item = 1;
          }
          const formData = new FormData();
          formData.append("attachment", this.record.file);
          formData.append("name", this.record.name);
          formData.append("commissioned", this.record.commissioned);
          formData.append("punch_item", this.record.punch_item);
          formData.append("tags", JSON.stringify(this.record.tags));
          formData.append("generate_document", this.record.generate_document);
          formData.append("document_name", this.record.document_name);
          if (this.record.description) {
            formData.append("description", this.record.description);
          }
          if (this.record.revision_name) {
            formData.append("revision_name", this.record.revision_name);
          }
          if (this.record.template_id) {
            formData.append("template_id", this.record.template_id);
          }
          if (this.parent_id) {
            formData.append("parent_id", this.parent_id);
          }
          if (this.record.type) {
            formData.append("type", this.record.type);
          }
          if (this.record.subtype) {
            formData.append("subtype", this.record.subtype);
          }
          if (this.record.asset_ids && this.record.asset_ids.length > 0) {
            formData.append("asset_ids", this.record.asset_ids);
          }
          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, formData, {headers: {"Content-Type": "multipart/form-data"}})
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.reset();
                  this.$emit("refresh");
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    linkDocument() {
      this.$refs.link_asset.load();
    },
    addDropFile(e) {
      this.record.file = e.dataTransfer.files[0];
      this.$refs.file_input.value = this.record.file;
    },
    reset() {
      this.record = {
        assets: [],
        asset_ids: []
      };
      this.closeModal(this.modal_name);
      this.parent_id = null;
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
  },
};
</script>